import SectionHeader from '../components/sections/header';
import SectionBreadcrumbs from '../components/sections/breadcrumbs';
import SectionPost from '../components/sections/post';
import SectionNewsletter from '../components/sections/newsletter';
import SectionFooter from '../components/sections/footer';

import React from 'react';
import { graphql } from 'gatsby';

import { IPage } from '../models/page.model';
import { IPost } from '../models/post.model';

import MainLayout from '../layouts/main-layout';
import SectionFactory from '../components/hoc/section-factory';
import UpwardsButton from '../components/molecules/upwards-button';

interface IPostProps {
    readonly data: {
        page: IPage;
        post: IPost;
    };
}

const Post: React.FC<IPostProps> = ({ data }) => {
    const { page, post } = data;

    const sectionsWithData = page.sections.map((section) => {
        if (section.type === 'post') {
            return {
                ...section,
                extendedItems: {
                    post: post,
                },
            };
        }
        return section;
    });

    return (
        <MainLayout>
            {sectionsWithData.map((section) => {
                return (
                    <SectionFactory
                        key={`section-${section.type}-${section.sectionId}`}
                        SectionComponent={sectionComponents[section.type]}
                        section={section}
                        TitleTag={section.isMain ? 'h1' : 'h4'}
                    />
                );
            })}
            <UpwardsButton />
        </MainLayout>
    );
};

export const query = graphql`
    query ($pageId: Int!, $postId: Int!, $locale: String!) {
        page(pageId: { eq: $pageId }) {
            sections {
                ...sectionFields
            }
        }
        post(postId: { eq: $postId }, locale: { eq: $locale }) {
            title
            lead
            content
            publishedAt
            displayPublishedAt
            mainCategory {
                title
                pathname
            }
            tags {
                tagId
                name
                pathname
            }
            author {
                profileId
                displayName
                locale
                description
                media {
                    ...mediaFields
                }
            }
            media {
                ...mediaFields
            }
        }
    }
`;

export default Post;

export { Head } from '@alterpage/gatsby-plugin-alterpress-page-creator';



const sectionComponents: Record<string, any> = {
    'header': SectionHeader,
    'breadcrumbs': SectionBreadcrumbs,
    'post': SectionPost,
    'newsletter': SectionNewsletter,
    'footer': SectionFooter,
};